import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get, compact } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildAuthorPath,
  buildPreProdContentAuthorPath,
  buildPreProdContentPath,
  buildRatedByPath,
  buildReviewPath,
  EditorialReviewEntities,
  EditorialReviewModel,
  FirstContentEntity,
} from 'client/data/models/editorial-review';
import { buildWhatsNewPath, VehicleComparisonsEntities, VehicleDetailsModel } from 'client/data/models/mmy-details';
import { PRE_PROD_ARTICLE_TYPE } from 'client/data/transforms/editorial-reviews/transform-make-model-year';
import { getRatingScale } from 'site-modules/shared/utils/editorial-helper';
import { CorePageParams, getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { separateFirstParagraph } from 'site-modules/shared/utils/string';
import { FirstContentArticles } from 'site-modules/core-page/components/articles/first-content-articles/first-content-articles';
import { ReviewSection } from 'site-modules/core-page/components/review-section/review-section';
import { ArticleAuthor } from 'site-modules/shared/components/preprod-articles/article-author';
import { PreProdContentArticles } from 'site-modules/core-page/components/articles/pre-prod-content-articles/pre-prod-content-articles';
import {
  EditorialHighlightsListsUI,
  HIGHLIGHTS_NAME,
} from 'site-modules/shared/components/editorial-highlights-lists/editorial-highlights-lists';
import { REVIEW_MENU_HASH } from 'site-modules/core-page/utils/constants';
import { MakeColumnNewsWidget } from 'site-modules/shared/components/column-news-widget/column-news-widget';
import { ReadyForEv, ReadyForEvTreehouse } from 'site-modules/shared/components/ready-for-ev/ready-for-ev';
import { TreehousePromotionCheck } from 'site-modules/shared/components/treehouse-promotion/treehouse-promotion-check';
import { RatingScorecard } from 'site-modules/core-page/components/scorecard-editorial-review/rating-scorecard/rating-scorecard';
import { Scorecard2025 } from 'site-modules/core-page/components/scorecard-editorial-review/scorecard-2025/scorecard-2025';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { TrimsComparatorLink } from 'site-modules/shared/components/core-page/trims-comparator-link/trims-comparator-link';
import { ReviewAnchorNav } from 'site-modules/core-page/components/scorecard-editorial-review/review-anchor-nav/review-anchor-nav';
import { getQuery } from 'client/utils/location';

import './scorecard-editorial-review.scss';

export function getEditorialHighlightsSectionsToRender({
  editorialReview,
  hasCoreHighlights,
  hasPreprodCta,
  isUsed,
  isPreprod,
}) {
  const hasProsCons = !!(get(editorialReview, 'cons.length') || get(editorialReview, 'pros.length'));
  const newSections = [
    HIGHLIGHTS_NAME.PROS,
    HIGHLIGHTS_NAME.CONS,
    HIGHLIGHTS_NAME.WHATS_NEW,
    HIGHLIGHTS_NAME.WHAT_TO_EXPECT,
  ];
  const sectionsWithoutRatingAndRanking = hasProsCons ? [HIGHLIGHTS_NAME.WHATS_NEW] : [];

  let mobileSectionsToRender;
  let desktopSectionsToRender;

  switch (true) {
    case isUsed: {
      break;
    }
    case isPreprod: {
      mobileSectionsToRender = hasCoreHighlights ? undefined : sectionsWithoutRatingAndRanking;
      desktopSectionsToRender = hasProsCons && hasPreprodCta ? undefined : sectionsWithoutRatingAndRanking;
      break;
    }
    // new
    default: {
      mobileSectionsToRender = hasCoreHighlights ? newSections : sectionsWithoutRatingAndRanking;
      desktopSectionsToRender = hasCoreHighlights ? newSections : sectionsWithoutRatingAndRanking;
      break;
    }
  }

  return {
    true: mobileSectionsToRender,
    false: desktopSectionsToRender,
  };
}

function ScorecardEditorialReviewBodyUI({
  params: { isElectric },
  editorialReview,
  reviewAuthor,
  reviewRatedBy,
  vehicle,
  isMobile,
  hasRouteYearParam,
  firstContent,
  firstContentAuthor,
  whatsNewContent,
  headingText,
  location,
  embeddedModules,
  isUsed,
  isPreprod,
  showScorecard,
  showHighlights,
  hasPreprodCta,
  hasCoreHighlights,
  hasTrimsLink,
  isCore6112Enabled,
  isCore6718Enabled,
  isCore6740Enabled,
}) {
  const {
    year,
    model: { name: modelName, slug: modelSlug },
    make: { name: makeName, slug: makeSlug },
    edmundsTypeCategories,
  } = vehicle;
  const vehicleDisplayName = `${year} ${makeName} ${modelName}`;
  const { expandedEntryId } = getQuery(location);

  const hasHighlights =
    showHighlights &&
    (get(editorialReview, 'cons.length') ||
      get(editorialReview, 'pros.length') ||
      get(firstContent, 'bullets.value.length') ||
      get(whatsNewContent, 'length'));

  const editorialHighlights = !!hasHighlights && (
    <Fragment>
      <EditorialHighlightsListsUI
        editorialReview={editorialReview}
        firstContent={firstContent}
        whatsNewContent={whatsNewContent}
        className="mb-1_5 mt-2"
        sectionsToRender={
          getEditorialHighlightsSectionsToRender({
            editorialReview,
            hasCoreHighlights,
            hasPreprodCta,
            isUsed,
            isPreprod,
          })[isMobile]
        }
        HeadingTag="h3"
        isPreprod={isPreprod}
      />
      <hr className="mb-2" />
    </Fragment>
  );

  if (!get(editorialReview, 'reviewLink') && !get(firstContent, 'articles.length')) {
    return (
      <Fragment>
        {!!editorialHighlights && (
          <ReviewSection
            id="scorecard-editorial-intro"
            author={get(reviewAuthor, 'contentMetadata', {})}
            ratedBy={get(reviewRatedBy, 'contentMetadata', {})}
            headingClassName="heading-3 mb-0_5"
            heading={headingText || `${vehicleDisplayName} Review`}
            headingTag="h2"
          >
            {editorialHighlights}
          </ReviewSection>
        )}
      </Fragment>
    );
  }

  const {
    title,
    ratings,
    scorecard,
    weRecommend,
    weRecommendTitle,
    goodCar,
    trimFeaturesTitle,
    trimFeaturesBodySnippet,
    trimFeaturesBody,
    reviewLink,
    summary,
    summaryTitle,
    needToKnow,
    needToKnowTitle,
    competitors,
    competitorsTitle,
    longTerm,
    longTermTitle,
    published,
    updated,
  } = editorialReview;

  // Show FL/FD content if has no editorial review
  if (!reviewLink) {
    const {
      articles,
      hasPreProdContent,
      dateModified,
      publishDate,
      subHeader,
      hasTeaser,
      hasMiscellaneous,
    } = firstContent;

    // When both 'teaser' and 'miscellaneous' exist then don't render 'teaser' here
    const renderArticles =
      hasTeaser && hasMiscellaneous ? articles.filter(({ type }) => type !== PRE_PROD_ARTICLE_TYPE.TEASER) : articles;

    return (
      <Fragment>
        {hasPreProdContent ? (
          <Fragment>
            <h2 className={classnames('heading-3 mb-0', { main: isPreprod })}>
              {headingText || title || `${vehicleDisplayName} Review`}
            </h2>
            {isPreprod && subHeader && (
              // inline styles to avoid adding critical css key
              <div style={{ lineHeight: '1.5rem', fontSize: '1.25rem', marginTop: '0.25rem' }}>{subHeader}</div>
            )}
            <ArticleAuthor
              author={get(firstContentAuthor, 'contentMetadata', {})}
              dateModified={dateModified}
              publishDate={publishDate}
              className="medium mb-1_5 mt-1"
              lazyLoadImage
              showBioTooltip
              isDateTextBelow
            />
            {editorialHighlights}
            <PreProdContentArticles
              articles={renderArticles}
              titleClassName="heading-5"
              collapseBodyText={renderArticles.length > 2 && !isPreprod}
              hasPhotos={false}
              hasEdmundsSaysIcon
              hasTreehousePromo={isElectric && isPreprod}
              expandedEntryId={expandedEntryId}
            />
            <MakeColumnNewsWidget
              makeSlug={makeSlug}
              makeName={makeName}
              modelName={modelName}
              className="my-1_5"
              isAds10779Enabled
            />
          </Fragment>
        ) : (
          <Fragment>
            {editorialHighlights}
            <FirstContentArticles
              articles={articles}
              isMobile={isMobile}
              isUsed={isUsed}
              noCollapse={isPreprod}
              suppressFirstMedia
            />
            <MakeColumnNewsWidget makeSlug={makeSlug} makeName={makeName} modelName={modelName} className="my-1_5" />
            <hr className="mt-1_5 mb-0" />
          </Fragment>
        )}
      </Fragment>
    );
  }

  const [first, rest] = separateFirstParagraph(summary);
  const rangeAndCostModule = get(embeddedModules, 'rangeAndCostModule');
  const coreSrpModule = get(embeddedModules, 'coreSrpModule');
  const evInsightsModule = get(embeddedModules, 'evInsightsModule');
  const shortVideosModule = get(embeddedModules, 'shortVideosModule');
  const incentiveAdModule = get(embeddedModules, 'incentiveAdModule');
  const buildPriceAdModule = get(embeddedModules, 'buildPriceAdModule');

  const hasRatingScorecard = !!(showScorecard && get(ratings, 'overall.rating'));
  const showTrimsLink = hasTrimsLink && !!trimFeaturesBody;

  const readyForEvPreprod = (
    <div className="preprod-ready-for-ev bg-cool-gray-90 px-1 py-1 rounded-12 mb-2">
      <ReadyForEv heading="Am I Ready for an EV?" className="bg-white rounded-12 px-1_5 py-1_5" />
    </div>
  );

  return (
    <div className="scorecard-editorial-review" data-tracking-parent="edm-entry-editorial-review">
      <h2 className="heading-3 mb-0_5">{headingText || title || `${vehicleDisplayName} Review`}</h2>
      {isCore6718Enabled && (
        <ReviewAnchorNav
          makeSlug={makeSlug}
          modelSlug={modelSlug}
          year={year}
          hasShortVideos={!!shortVideosModule}
          hasRangeAndCost={!!rangeAndCostModule}
          hasEvInsights={!!evInsightsModule}
          isMobile={isMobile}
        />
      )}
      <ReviewSection
        id="scorecard-editorial-intro"
        author={isCore6718Enabled ? undefined : get(reviewAuthor, 'contentMetadata', {})}
        ratedBy={isCore6718Enabled ? undefined : get(reviewRatedBy, 'contentMetadata', {})}
      >
        {!!editorialHighlights && <ScrollElement id={REVIEW_MENU_HASH.HIGHLIGHTS}>{editorialHighlights}</ScrollElement>}
        {isCore6718Enabled && !!shortVideosModule && (
          <ScrollElement id={REVIEW_MENU_HASH.SHORTS}>{shortVideosModule}</ScrollElement>
        )}
      </ReviewSection>
      <ScrollElement id={REVIEW_MENU_HASH.OVERVIEW}>
        <ReviewSection
          author={isCore6718Enabled ? get(reviewAuthor, 'contentMetadata', {}) : undefined}
          body={first}
          heading={summaryTitle}
          published={published}
          updated={updated}
          isCore6718Enabled={isCore6718Enabled}
        />
      </ScrollElement>
      {!!coreSrpModule && coreSrpModule}
      {!!rangeAndCostModule && <ScrollElement id={REVIEW_MENU_HASH.COST_TO_DRIVE}>{rangeAndCostModule}</ScrollElement>}
      {!!evInsightsModule && <ScrollElement id={REVIEW_MENU_HASH.EV_INSIGHTS}>{evInsightsModule}</ScrollElement>}
      {incentiveAdModule}
      <ReviewSection body={rest} />
      <ReviewSection body={needToKnow} heading={needToKnowTitle} />
      <ReviewSection body={competitors} heading={competitorsTitle} />
      <ReviewSection body={longTerm} heading={longTermTitle} />

      {isElectric && isPreprod && (
        <TreehousePromotionCheck fallback={readyForEvPreprod}>
          <div className="preprod-treehouse-promotion bg-cool-gray-90 px-1 py-1 rounded-12 mb-2">
            <ReadyForEvTreehouse
              className="bg-white rounded-12 px-1_5 py-1_5 mx-auto"
              heading="Am I Ready for an EV?"
              creativeId="treehouse-promotion"
            />
          </div>
        </TreehousePromotionCheck>
      )}

      {hasRatingScorecard && (
        <ScrollElement id={REVIEW_MENU_HASH.RATING}>
          {scorecard && isCore6740Enabled ? (
            <Scorecard2025
              scorecard={scorecard}
              makeName={makeName}
              modelName={modelName}
              year={year}
              scale={getRatingScale(editorialReview)}
              embeddedModules={embeddedModules}
              ratedBy={get(reviewRatedBy, 'contentMetadata', {})}
              isMobile={isMobile}
            />
          ) : (
            <RatingScorecard
              ratings={ratings}
              scale={getRatingScale(editorialReview)}
              edmundsTypeCategories={edmundsTypeCategories}
              modelName={modelName}
              embeddedModules={embeddedModules}
              isMobile={isMobile}
              showMobileSummary={isPreprod}
              expandedEntryId={expandedEntryId}
              // TODO: Review after core-6718
              makeName={makeName}
              year={year}
              ratedBy={get(reviewRatedBy, 'contentMetadata', {})}
              isCore6718Enabled={isCore6718Enabled}
            />
          )}
        </ScrollElement>
      )}
      {buildPriceAdModule}
      <ScrollElement id="recommended-trim">
        <ReviewSection
          body={weRecommend}
          heading={weRecommendTitle || `Which ${modelName} does Edmunds recommend?`}
          bodyCreativeId="edm-we-recommend"
          headingTag="h2"
        />
      </ScrollElement>
      {showTrimsLink && <TrimsComparatorLink className="mb-1_5" vehicle={vehicle} isMobile={isMobile} />}
      {!(showTrimsLink && isCore6112Enabled) && (
        <ReviewSection
          body={isPreprod ? compact([trimFeaturesBodySnippet, trimFeaturesBody]).join('\n') : trimFeaturesBodySnippet}
          collapsedBody={isPreprod ? null : trimFeaturesBody}
          id="scorecard-editorial-trims"
          heading={trimFeaturesTitle || `${hasRouteYearParam ? `${year} ` : ''}${makeName} ${modelName} models`}
          headingTag="h2"
          bodyClassName="trim-features mb-0_75"
          collapsedBodyClasName="trim-features"
          collapseTrackingId="view_trim_features"
          bodyCreativeId="edm-trim-features"
          afterBodyChildren={
            <MakeColumnNewsWidget
              makeSlug={makeSlug}
              makeName={makeName}
              modelName={modelName}
              className="my-1_5"
              creativeId="news_module"
            />
          }
        />
      )}
      <ReviewSection body={goodCar} heading={`Is the ${makeName} ${modelName} a good car?`} headingTag="h2" />
    </div>
  );
}

ScorecardEditorialReviewBodyUI.propTypes = {
  params: CorePageParams.isRequired,
  editorialReview: EditorialReviewEntities.EditorialReview,
  reviewAuthor: EditorialReviewEntities.Author,
  reviewRatedBy: EditorialReviewEntities.Author,
  firstContentAuthor: EditorialReviewEntities.Author,
  firstContent: FirstContentEntity,
  whatsNewContent: VehicleComparisonsEntities.WhatsNewContent,
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      name: PropTypes.string,
    }),
    model: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  embeddedModules: PropTypes.shape({
    pricingModule: PropTypes.node,
    rangeAndCostModule: PropTypes.node,
    coreSrpModule: PropTypes.node,
    evInsightsModule: PropTypes.node,
    shortVideosModule: PropTypes.node,
    incentiveAdModule: PropTypes.node,
    buildPriceAdModule: PropTypes.node,
  }),
  headingText: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  hasRouteYearParam: PropTypes.bool,
  isUsed: PropTypes.bool,
  isPreprod: PropTypes.bool,
  showScorecard: PropTypes.bool,
  showHighlights: PropTypes.bool,
  hasPreprodCta: PropTypes.bool,
  hasCoreHighlights: PropTypes.bool,
  hasTrimsLink: PropTypes.bool,
  isCore6112Enabled: PropTypes.bool,
  isCore6718Enabled: PropTypes.bool,
  isCore6740Enabled: PropTypes.bool,
};

ScorecardEditorialReviewBodyUI.defaultProps = {
  editorialReview: null,
  reviewAuthor: null,
  reviewRatedBy: null,
  firstContent: null,
  whatsNewContent: null,
  firstContentAuthor: null,
  vehicle: null,
  embeddedModules: null,
  headingText: null,
  location: null,
  isMobile: false,
  hasRouteYearParam: false,
  isUsed: false,
  isPreprod: false,
  showScorecard: true,
  showHighlights: false,
  hasPreprodCta: false,
  hasCoreHighlights: false,
  hasTrimsLink: false,
  isCore6112Enabled: false,
  isCore6718Enabled: false,
  isCore6740Enabled: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  location: state.pageContext?.location,
  isCore6740Enabled: !!state.featureFlags?.['core-6740-scorecard-2025'],
});

export const ScorecardEditorialReviewBody = connect(mapStateToProps)(
  connectToModel(ScorecardEditorialReviewBodyUI, {
    editorialReview: bindToPath(({ params }) => buildReviewPath(params), EditorialReviewModel),
    reviewAuthor: bindToPath(({ params }) => buildAuthorPath(params), EditorialReviewModel),
    reviewRatedBy: bindToPath(({ params }) => buildRatedByPath(params), EditorialReviewModel),
    firstContent: bindToPath(
      ({ vehicle }) => buildPreProdContentPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel
    ),
    firstContentAuthor: bindToPath(
      ({ vehicle }) => buildPreProdContentAuthorPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel
    ),
    whatsNewContent: bindToPath(
      ({ params, showHighlights }) => showHighlights && buildWhatsNewPath(params),
      VehicleDetailsModel
    ),
  })
);
